<template>
  <footer
    class="contain-content b-t b-grey-50 bg-white content-visibility-auto"
    style="contain-intrinsic-size: auto 100vw auto 51.875rem"
  >
    <div class="mx-a divide-y divide-grey-70" style="max-width: 90rem">
      <template v-if="enableLoyaltyFeatures && showLoyaltyContent">
        <section v-show="!isLoyaltyMember" class="px-4 py-8 md:text-center">
          <div>
            <h2 class="mb-2 title-3">
              {{ $t.footerContent.loyalty.header }}
            </h2>
            <p class="mb-6 text-sm fw-light md:mb-4">
              {{ $t.footerContent.loyalty.description }}
            </p>
          </div>
          <vf-button
            class="c-white <md:w-full"
            :size="{ sm: 'sm', md: 'xs' }"
            :theme="loyaltyCtaTheme"
            :variant="loyaltyCtaTheme ? 'themable' : 'primary'"
            @click="DialogSignIn.open({ formLocation: 'modal:single:footer:none' })"
          >
            {{ $t.footerContent.loyalty.button }}
          </vf-button>
        </section>
      </template>
      <vf-footer-columns :footer-data="data" />
      <div class="py-8">
        <div v-style:w="{ md: '63.75rem' }" class="md:mx-a md:flex divide-x divide-grey-70 <md:divide-y">
          <div
            v-if="!hideNewsletter && enableLoyaltyFeatures && showLoyaltyContent"
            v-show="!pending"
            v-style:w="{ md: '21.5rem' }"
            class="f-col items-start px-4 pb-8 between md:pl-0 md:pr-4"
          >
            <form-newsletter
              class="w-full"
              class-heading="md:text-left"
              class-subheading="md:text-left text-sm"
              class-submit="md:self-start!"
              :show-hint="false"
            />
          </div>

          <div
            v-style:w="{ md: '21.5rem' }"
            class="f-col items-start divide-y divide-grey-70 md:px-4"
          >
            <section
              v-if="showStoreLocator"
              class="f-col items-start <md:px-4 <md:py-8 md:pb-8"
              :class="{
                'md:flex-row md:w-3/5 md:justify-evenly':
                  !showLoyaltyContent && !showCustomizationContent,
                'flex-1': showLoyaltyContent || showCustomizationContent,
              }"
            >
              <div>
                <h2 class="mb-2 title-3">
                  {{ $t.footerContent.storeLocator.header }}
                </h2>
                <p class="mb-6 text-sm md:mb-4 fw-light">
                  {{ $t.footerContent.storeLocator.description }}
                </p>
              </div>
              <vf-button
                class="<md:w-full"
                :size="{ sm: 'sm', md: 'xs' }"
                to="/store-locator"
                @click="$gtm.push('footer.onClickFooterLink', 'Store Locator')"
              >
                {{ $t.footerContent.storeLocator.button }}
              </vf-button>
            </section>
          </div>
          <div
            v-style:w="{ md: '21.5rem' }"
            class="f-col items-start px-4 divide-y divide-grey-70 <md:py-8 md:pl-4 md:pr-0"
          >
            <section
              v-if="showSocialMediaLinks"
              class="items-start px-4 between"
            >
              <div>
                <h2 class="mb-2 title-3">
                  {{ $t.footerContent.social.header }}
                </h2>
                <div class="flex space-x-4 md:space-x-2">
                  <div v-for="(to, name) in data?.links?.social" :key="name">
                    <base-link :aria-label="name" :to>
                      <vf-icon :name size="lg" />
                    </base-link>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <vf-footer-legal-links v-if="data?.links?.legal" class="b-t b-grey-70 mx-0!" :links="data.links.legal">
        <base-link class="<md:hidden" to="/">
          <vf-logo loading="lazy" style="max-width: 7.5rem" />
        </base-link>
      </vf-footer-legal-links>
    </div>
  </footer>
</template>

<script lang="ts" setup>
defineProps<{
  hideNewsletter?: boolean
  pending?: boolean
}>()

const { data } = await useApi().internal.getFooter()
const { enableLoyaltyFeatures } = useFeatureFlags()

const {
  showCustomizationContent,
  showLoyaltyContent,
  showSocialMediaLinks,
  showStoreLocator,
  loyaltyCtaTheme
} = useAppConfig().components.vf.footer

const { DialogSignIn } = useDialogsStore()
const { isLoyaltyMember } = storeToRefs(useUserStore())
</script>
